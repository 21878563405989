<template>
<error-content code="401" :desc="$t('Oh～您没有浏览这个页面的权限～')" :src="src"></error-content>
</template>

<script>
import errorContent from './error-content.vue'

export default {
  name: 'error_401',
  components: {
    errorContent
  },
  data () {
    return {
      src: 'https://file.40017.cn/zhuketong/zhuketong/image/oms/page_401.png'
    }
  }
}
</script>
