<template>
<div class="error-page">
    <div class="user-wrapper" @click="handleLogoutClick">
      <label>{{getUserName()}}</label>
      <label>|</label>
      <label class="logoutBtn">{{$t('退出')}}</label>
    </div>

    <div class="content-con">
      <img :src="src" alt="404"/>
      <h5>{{desc}}</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import systemHelper from '@/libs/system'
import basicHelper from '@/view/common/basic'

export default {
  name: 'error_content',
  props: {
    desc: String,
    src: String
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    /**
     * 获取用户名称
     */
    getUserName() {
      return this.userInfo.userName || '';
    },

    /**
     * 退出登录操作
     */
    handleLogoutClick() {
      basicHelper.logout().then(() => {
        systemHelper.loginByOAuth();
      });
    }
  }
}
</script>

<style lang="scss" src="./error.scss">

</style>
